import Swiper, { Navigation, Pagination, Autoplay } from 'swiper'
import "swiper/swiper.min.css";
import g from './globals'

Swiper.use([Navigation, Pagination, Autoplay]);

if (g.elementInDom('.slider')) {
    var sliders = document.querySelectorAll('.slider-container')

    sliders.forEach((obj, index) => {
        let checkPagination = (obj.querySelectorAll('.swiper-slide').length > 1) 
            ? '.swiper-pagination' 
            : 'pagination-none'

        new Swiper(obj, {
            direction: 'horizontal',
            autoplay: {
                delay: 2500,
                disableOnInteraction: false,
            },
            noSwipingSelector: 'a',
            a11y: false,
            pagination: {
                el: obj.querySelector(checkPagination),
                clickable: true,
            },
        })
    })
}
