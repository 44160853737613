import g from './globals'

if (g.elementInDom('.wine-item')) {
    const wineItems = document.querySelectorAll('.wine-item');

    // Loop over the array-like structure that
    // qsa returns. Find the remainder of dividing
    // the index by the supplied `n` argument. If it's
    // zero create a new div element and add it `after`
    // the current div
    function insertAfter(n) {
        for (let i = 0; i < wineItems.length; i++) {
            if ((i + 1) % n === 0) {
            const div = createDiv();
            wineItems[i].after(div);
            }
        }
    }

    // For convenience a function that creates
    // a new paragragh element wrapped in a div.
    // It uses the text passed in as an argument
    function createDiv() {
        const div = document.createElement('div');
        div.className = 'cell ';
        div.className += 'anim-border-x';
        div.className += ' anim-grow-x-start';

        if (g.mq('639px', 'max')) {
            div.className += ' hide-for-medium';
        } else {
            div.className += ' show-for-medium';
        }

        div.innerHTML = '<div class="line"></div>'
        return div;
    }

    // Call the main function. The arguments are
    // the count where you add a new div, and the
    // text you want to appear in the paragraph
    setTimeout(() => {

        if (g.mq('639px', 'max')) {
            insertAfter(1);
        } else {
            insertAfter(2);
        }
    }, 1000)
}