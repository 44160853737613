import g from './globals'
import { gsap, Power2 } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger.js";

if (g.elementInDom('.wine-item, .wine-callout') && g.mq('639px', 'max')) {
    const wineItems = document.querySelectorAll('.wine-item, .wine-callout')

    wineItems.forEach((obj, index) => {
        let animateBg = gsap.timeline({
            // yes, we can add it to an entire timeline!
            scrollTrigger: {
                trigger: obj,
                start: 'top-=300px center',
                end:  'center center',
                scrub: true,
            }
        })

        animateBg.fromTo(obj.querySelectorAll('.hover-image'),
            {opacity: 0}, {opacity: 1, duration: 100}
        )
    })
}