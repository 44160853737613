import { gsap, Power2 } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger.js";
import g from './globals'

gsap.registerPlugin(ScrollTrigger)

if (g.elementInDom('.anim-container')) {
    const animContainers = document.querySelectorAll('.anim-container')

    animContainers.forEach((obj, index) => {
        let animGrowLeft = gsap.timeline({
            // yes, we can add it to an entire timeline!
            scrollTrigger: {
                trigger: obj.querySelector('.anim-grow-left'),
                start: 'top-=300px center',
                end:  'center center',
                scrub: true,
            }
        })
        let animGrowRight = gsap.timeline({
            // yes, we can add it to an entire timeline!
            scrollTrigger: {
                trigger: obj.querySelectorAll('.anim-grow-right'),
                start: 'top-=200px bottom',
                end:  '-=600px',
                scrub: true,
            }
        })
        let animGrowTop = gsap.timeline({
            // yes, we can add it to an entire timeline!
            scrollTrigger: {
                trigger: obj.querySelectorAll('.anim-grow-top'),
                start: 'top-=200px center',
                end:  'center center',
                scrub: true,
            }
        })
        let animFadeLeft = gsap.timeline({
            // yes, we can add it to an entire timeline!
            scrollTrigger: {
                trigger: obj.querySelectorAll('.anim-fade-left'),
                start: 'top-=200px center',
                end:  'center center',
                scrub: true,
            }
        })
        let animFadeRight = gsap.timeline({
            // yes, we can add it to an entire timeline!
            scrollTrigger: {
                trigger: obj.querySelectorAll('.anim-fade-right'),
                start: 'top-=200px center',
                end:  'center center',
                scrub: true,
            }
        })
        let animFadeTop = gsap.timeline({
            // yes, we can add it to an entire timeline!
            scrollTrigger: {
                trigger: obj.querySelectorAll('.anim-fade-top'),
                start: 'top-=200px center',
                end:  'center center',
                scrub: true,
                stagger: 1,
            }
        })

        animGrowLeft.to(obj.querySelectorAll('.anim-grow-left .line'),
            {width: '100%', duration: 100}
        )
        animGrowRight.to(obj.querySelectorAll('.anim-grow-right .line'),
            {width: '100%', duration: 100}
        )
        animGrowTop.to(obj.querySelectorAll('.anim-grow-top .line'),
            {height: 'calc(100% - 40px)', duration: 100}
        )
        animFadeLeft.fromTo(obj.querySelectorAll('.anim-fade-left'),
            {x: '-100px', opacity: 0}, {x: 0, opacity: 1, duration: 100}
        )
        animFadeRight.fromTo(obj.querySelectorAll('.anim-fade-right'),
            {x: '100px', opacity: 0}, {x: 0, opacity: 1, duration: 100}
        )
        animFadeTop.fromTo(obj.querySelectorAll('.anim-fade-top'),
            {y: '-50px', opacity: 0}, {y: 0, opacity: 1, duration: 100}
        )
    })
}