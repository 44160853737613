import g from './globals'

 // get the sticky element
 const sticky = document.querySelector('header'); 

 const observer = new IntersectionObserver( 
     ([e]) => e.target.classList.toggle('isSticky', e.intersectionRatio < 1),
     {threshold: [1]}
 )

 observer.observe(sticky)